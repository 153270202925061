// import logo from './logo.svg';
import './App.css';
import React from 'react'
import Sampledata from './Sampledata'


function App() {
  return (
    <Sampledata />
  );
}

export default App;
